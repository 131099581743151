import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "links"
    }}>{`Links`}</h1>
    <p>{`Link text should be as descriptive as possible. Links should not incorporate urls such as `}<a parentName="p" {...{
        "href": "http://www.name.com"
      }}>{`http://www.name.com`}</a>{` but name.com is acceptable.When using name.com an external link icon is not necessary, name would need the icon.
When to use one style or another would be defined by copy.`}</p>
    <p>{`Visit Cerner.com`}</p>
    <p>{`Fill out Cerner's `}{`[i]`}{` form`}</p>
    <p>{`Formatting of Phone numbers - Only dashes in between, no parentheses. Link styled on mobile, suppress link style on Desktop and Lrg Tablet. Mobile view - show link color, desktop view, show as normal text (possible without javascript)`}</p>
    <p>{`Use of text links, no caps in label`}</p>
    <p>{`Icons - use only when absolutely necessary`}</p>
    <p>{`PDF - makes sense, leaving the site they make sense`}</p>
    <p>{`Stray away from putting links away from site in main navigation`}</p>
    <p>{`Never say "click here"`}</p>
    <p>{`Actual name of item should be linked in a paragraph`}</p>
    <p>{`Be as descriptive as possible to give more detail as opposed to "read more"`}</p>
    <p>{`Ok to use the tld (.com)`}</p>
    <p>{`Contact Us with arrow as example`}</p>
    <p>{`Code.cerner.com`}</p>
    <p>{`Default of linking headers? - pick one or the other, include a button or text link in the section`}</p>
    <p>{`Designate use cases for buttons or text links`}</p>
    <p>{`Non-breaking space should be used if an icon is included`}</p>
    <p>{`Formatting of Addresses`}</p>
    <p>{`Name of facility should be bolded`}</p>
    <p>{`Address should fall under it`}</p>
    <p>{`Phone number should be a link by default`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p>{`Relative Link: `}<inlineCode parentName="p">{`<a href="#">Link Text</a>`}</inlineCode></p>
    <p>{`External Link: `}<inlineCode parentName="p">{`<p>this is text and here is an <a href="#">external link <i class="icon-external-link"></i></a></p>`}</inlineCode></p>
    <p>{`Phone Link: `}<inlineCode parentName="p">{`<a href="#">Link Text</a>`}</inlineCode></p>
    <p>{`Email Link: `}<inlineCode parentName="p">{`<a href="#">Link Text</a>`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <p>{`Hover state - for each design this style can vary, but a hover state for links should be defined`}</p>
    <p>{`Visited/Selected state - also our default is to define it (different color, outline, etc)`}</p>
    <h2 {...{
      "id": "using-different-kinds"
    }}>{`Using different kinds`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      